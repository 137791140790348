<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Please arrange each of the following steps in the correct order for this experiment.
      </p>

      <drag-to-rank-input
        v-model="inputs.inputs"
        :items="optionsShuffled"
        class="mb-0"
        style="max-width: 631px"
        prepend-text="First Step"
        append-text="Last Step"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'CPP_FlowChartForExperiment',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputs: [],
      },
      options: [
        'Add 6 M $\\ce{HCl}$',
        'Centrifuge and separate liquid (S1) from the solid',
        'Add deionized water to the solid (P1) and heat',
        'Centrifuge and separate liquid (S2) from the solid',
        'Add 1 M $\\ce{K2CrO4}$ to both liquids',
        'Add 15 M $\\ce{NH3}$ to solid (P1)',
        'Centrifuge and separate liquid (S3) from solid',
        'Add 6 M $\\ce{HNO3}$ to liquid (S3) until acidic',
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id ?? 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
